import React from 'react';
import styled from 'styled-components';
import NoLayout from '../layouts/NoLayout';
import * as colors from '../util/colors';

// SEO INFORMATION
const pageMeta = {
    title: `KEY+YOU HCP Video`,
    keywords: `keywords`,
    description: `description`
}

const GreenBarWrapper = styled.div`
    border-top: 7px solid ${colors.keytrudaGreen};
    padding-top: 50px;
`;

const VideoBorder = styled.div`
    margin-top: 40px;
    border: 1px solid #bbbbbb;
`;

const VideoContainer = styled.div`
    overflow: hidden;
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
`;

const ContentWrapper = styled.div`
    max-width: 840px;
    padding: 0 20px;
    margin: 0 auto;
`;


const Page = ({ location }) => {

    return (
        <NoLayout pageMeta={pageMeta} location={location}>
            <GreenBarWrapper>
                <ContentWrapper>
                    <VideoBorder>
                        <VideoContainer>
                            <iframe frameBorder="0" src="https://players.brightcove.net/1905768940001/meAxH3mZK_default/index.html?videoId=6261481969001" allowfullscreen webkitallowfullscreen></iframe>
                        </VideoContainer>
                    </VideoBorder>
                </ContentWrapper>
            </GreenBarWrapper>
        </NoLayout>
    );
};

export default Page;
